import * as auth from './auth';
import * as user from './user';
import * as publicLinks from './publicLinks';
import * as countries from './countries';
import * as publicInvites from './publicInvites';
import * as specialCases from './specialCases';
import * as contacts from './contacts';
import * as transactions from './transactions';
import * as accounts from './accounts';
import * as stat from './stat';
import * as clientDrafts from './clientDrafts';
import * as clients from './clients';
import * as bankEntities from './bankEntities';
import * as directoryItems from './directoryItems';
import * as clientUsers from './clientUsers';
import * as tariff from './tariff';
import * as counters from './counters';
import * as notifications from './notifications';
import * as search from './search';
import * as paymentMethods from './paymentMethods';
import * as addMoneySubmissions from './addMoneySubmissions';
import * as invoices from './invoices';
import * as supportingDocumentsRequests from './supportingDocumentsRequests';
import * as documents from './documents';
import * as labels from './labels';
import * as drafts from './drafts';
import * as roles from './roles';
import * as auditLogs from './auditLogs';
import * as rfi from './rfi';
import * as multipleApproval from './multipleApproval';
import * as banners from './banners';
import * as wise from './wise';
import * as abilities from './abilities';
import * as payments from './payments';
import * as paymentsDetailsLookup from './paymentDetailsLookup';

export const api = {
  auth,
  user,
  publicLinks,
  countries,
  publicInvites,
  specialCases,
  contacts,
  transactions,
  accounts,
  stat,
  clientDrafts,
  clients,
  bankEntities,
  directoryItems,
  clientUsers,
  tariff,
  counters,
  notifications,
  search,
  paymentMethods,
  addMoneySubmissions,
  invoices,
  labels,
  supportingDocumentsRequests,
  documents,
  roles,
  auditLogs,
  drafts,
  rfi,
  multipleApproval,
  banners,
  wise,
  abilities,
  payments,
  paymentsDetailsLookup,
};
